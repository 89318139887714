import { useState, useEffect } from "react";
import {
  AuthContext,
  AuthorizationStage,
  userDataType
} from "./context/AuthContext";

import { LocalStorage } from "config/keys";
import {
  Enum_ReserveFor,
  // VisitDoctor,
  VisitDoctorContext
} from "views/private/VisitDoctor/VisitDoctorContext";
import { HealthCheckContext } from "views/public/HealthCheck/HealthCheckContext";
import { Enum_ReserveBy } from "views/private/VisitDoctor/views/ReserveVisit/views/ReserveBy/ReserveBy.types";

type AuthProviderProps = {};

export function AuthProvider({
  children
}: React.PropsWithChildren<AuthProviderProps>) {
  const [authorizationStage, setAuthorizationStage] =
    useState<AuthorizationStage>(AuthorizationStage.UNAUTHORIZED);
  const [token, setToken] = useState<null | string>(null);
  const [userData, setUserData] = useState<null | userDataType>(null);
  const [reserveForm, setReserveForm] = useState<any>();
  const [healthCheckForm, setHealthCheckForm] = useState({
    title: "",
    phone: ""
  });

  useEffect(() => {
    const localStorageToken =
      localStorage.getItem(LocalStorage.AUTH_TOKEN) || null;
    localStorageToken && setToken(localStorageToken);
    const localStorageUserData = localStorage.getItem("userData") || null;
    if(localStorageUserData === 'undefined') {
      localStorage.removeItem("userData");
      console.log(localStorageUserData);
    }
    localStorageUserData && setUserData(JSON.parse(localStorageUserData));
    if (!token) {
      setAuthorizationStage(AuthorizationStage.UNAUTHORIZED);
    } else if (token && userData) {
      setAuthorizationStage(AuthorizationStage.AUTHORIZED);
    }
  }, []);

  useEffect(() => {
    const localStorageToken =
      localStorage.getItem(LocalStorage.AUTH_TOKEN) || null;
    localStorageToken && setToken(localStorageToken);
    const localStorageUserData = localStorage.getItem("userData") || null;
    if(localStorageUserData === 'undefined') {
      localStorage.removeItem("userData");
      console.log(localStorageUserData);
    }
    localStorageUserData && setUserData(JSON.parse(localStorageUserData));
    if (!token) {
      setAuthorizationStage(AuthorizationStage.UNAUTHORIZED);
    } else if (token && userData) {
      setAuthorizationStage(AuthorizationStage.AUTHORIZED);
    }
  }, [token]);

  useEffect(() => {
    try {
      setReserveForm(JSON.parse(sessionStorage.getItem("appointment") || "{}"));
    } catch {
      setReserveForm({
        ReserveFor: Enum_ReserveFor.Me,
        ReservePatient: {
          pid: "",
          firstName: "",
          lastname: "",
          phone: "",
          dateOfBirth: "",
          gender: "",
          citizenship: ""
        },
        ReserveBy: Enum_ReserveBy.branch,
        ReserveBranch: "",
        ReserveService: "",
        ReserveServiceDetailed: "",
        ReserveDoctor: "",
        ReserveTime: ""
      });
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("appointment", JSON.stringify(reserveForm));
  }, [reserveForm]);

  return (
    <AuthContext.Provider
      value={{
        authorizationStage,
        setAuthorizationStage,
        token,
        setToken,
        userData,
        setUserData
      }}
    >
      <VisitDoctorContext.Provider
        value={{
          reserveForm,
          setReserveForm
        }}
      >
        <HealthCheckContext.Provider
          value={{
            healthCheckForm,
            setHealthCheckForm
          }}
        >
          {children}
        </HealthCheckContext.Provider>
      </VisitDoctorContext.Provider>
    </AuthContext.Provider>
  );
}
